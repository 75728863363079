import * as React from 'react';

import { Link } from 'gatsby';

import {
  Layout,
  SEO,
  Hero,
  ContactFormHero,
  HeadingWithCopy,
} from '../components';
import { useGraphQL } from '../hooks';
import config from '../utils/config';

function ContactUsPage() {
  return (
    <Layout>
      <SEO
        title="Contact Us | Aerial Firefighting Services"
        description={`Contact ${config.title} to learn more about our aerial firefighting capabilities and how we can assist you with reliable and effective fire suppression.`}
      />
      <ContactHero />
      <ContactRMAviation />
      <ContactFormHero />
    </Layout>
  );
}

function ContactHero() {
  const { contactUsHeroImage } = useGraphQL();

  return (
    <Hero image={contactUsHeroImage.childImageSharp.fluid}>
      <h1 className="heading-1">Contact Us</h1>
    </Hero>
  );
}

function ContactRMAviation() {
  return (
    <HeadingWithCopy heading={`Contact ${config.title}`} subheading="">
      <p>
        Our International Air Operators Certificate (AOC) allows us to take our
        experience in fire suppression services globally. If you are interested
        in knowing how we can help you, please{' '}
        <Link to="#contact-form">get in touch.</Link>
      </p>
      <p>
        Or if you have any queries about {config.title}, our team is here to
        assist.
      </p>
      <h2 className="text-teal">Head Office</h2>
      <p>
        R&amp;M Aviation’s head office is in Port Macquarie, New South Wales,
        Australia.
      </p>

      <p>
        We have operational bases in Mudgee, Orange, and Armidale in New South
        Wales, as well as relationships with airport facilities across Australia
        to operate as required.
      </p>

      <p>
        <span className="font-bold text-teal">Postal Address:</span>
        <br />
        {config.address.line1}, {config.address.line2}
      </p>

      <p>
        <span className="font-bold text-teal">Phone: </span>
        <a href={`tel:${config.phone}`} className="no-underline">
          {config.phone}
        </a>
        <br />
        <span className="font-bold text-teal">Fax: </span>
        <a href={`tel:${config.fax}`} className="no-underline">
          {config.fax}
        </a>
        <br />
        <span className="font-bold text-teal">Email: </span>
        <a href={`mailto:${config.email}`} className="no-underline">
          {config.email}
        </a>
      </p>

      <h2 className="text-teal">Contact Our Relationship Manager</h2>
      <p>
        For more information about R&amp;M Aviation and to find out how we can
        assist you, please contact our key relationship manager, Kaylah Hacio.
      </p>

      <p>
        <span className="font-bold text-teal">Mobile: </span>
        <a
          href={`tel:${config.relationshipManager.phone}`}
          className="no-underline"
        >
          {config.relationshipManager.phone}
        </a>
        <br />
        <span className="font-bold text-teal">Email: </span>
        <a
          href={`mailto:${config.relationshipManager.email}`}
          className="no-underline"
        >
          {config.relationshipManager.email}
        </a>
      </p>
    </HeadingWithCopy>
  );
}

export default ContactUsPage;
